import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

interface GnbNavbarOpenIconProps extends TSvgProps {
  isOpen?: boolean
}

const GnbNavbarOpenIcon: FC<GnbNavbarOpenIconProps> = ({
  isOpen,
  ...props
}) => {
  return isOpen ? (
    <svg
      cursor="pointer"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 32 32"
      {...props}
    >
      <desc>메뉴닫기</desc>
      <g data-name="icon / 32 / close" transform="translate(-449 -197)">
        <circle
          cx="16"
          cy="16"
          r="16"
          fill="#eee"
          data-name="타원 2487"
          transform="translate(449 197)"
        ></circle>
        <g data-name="그룹 43521">
          <path
            fill="none"
            d="M457 205h16v16h-16z"
            data-name="사각형 28320"
          ></path>
          <g fill="#fff" stroke="#333" strokeWidth="1.2" data-name="그룹 41898">
            <g data-name="사각형 28321" transform="rotate(45 -19.544 658.515)">
              <rect width="16.037" height="1.8" stroke="none" rx="0.9"></rect>
              <rect
                width="14.837"
                height="0.6"
                x="0.6"
                y="0.6"
                fill="none"
                rx="0.3"
              ></rect>
            </g>
            <g
              data-name="사각형 28322"
              transform="rotate(-45 492.581 -444.782)"
            >
              <rect width="16.037" height="1.8" stroke="none" rx="0.9"></rect>
              <rect
                width="14.837"
                height="0.6"
                x="0.6"
                y="0.6"
                fill="none"
                rx="0.3"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      cursor="pointer"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <desc>메뉴펼치기</desc>
      <rect x="3" y="7" width="21" height="2" rx="1" fill="#333333" />
      <rect x="3" y="13" width="14" height="2" rx="1" fill="#333333" />
      <rect x="3" y="19" width="21" height="2" rx="1" fill="#333333" />
    </svg>
  )
}

export default GnbNavbarOpenIcon
